import { Injectable } from '@angular/core';
import { ICredentials } from '../models/credentials.model';
import { LoginBody } from '../models/auth.model';
import { catchError, map, Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthRepository } from '../repositories/auth.repository';
import { CredentialsService } from './credentials.service';
import { FeedbackService } from '../../../shared/services/feedback.service';
import { IResponse } from '../../../core/models/response.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private repository: AuthRepository,
    private credentialsService: CredentialsService,
    private feedbackService: FeedbackService
  ) {}

  login(body: LoginBody): Observable<IResponse> {
    return this.repository.login(body).pipe(
      map((res) => {
        this.credentialsService.setCredentials(res.data);
        return res;
      }),
      catchError((err: HttpErrorResponse) => {
        this.feedbackService.httpWarn(err);
        throw err;
      })
    );
  }

  logout(): Observable<void> {
    return this.repository.logout().pipe(
      map((res) => {
        this.credentialsService.logout();
        return res;
      }),
      catchError((err: HttpErrorResponse) => {
        this.feedbackService.httpError(err);
        throw err;
      })
    );
  }
}
