import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IResponse } from '../../../core/models/response.model';
import { ForgotPasswordBody } from '../models/password.model';

@Injectable({
  providedIn: 'root',
})
export class ForgotPasswordRepository {
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  forgotPassword(body: ForgotPasswordBody): Observable<IResponse> {
    return this.http.post<IResponse>(`${this.baseUrl}/password/forgot`, body);
  }

  //   changePassword(body: IChangePasswordBody): Observable<void> {
  //     return this.http.post<void>(`${this.baseUrl}/user/change-password`, body);
  //   }
}
