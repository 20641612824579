import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ICredentials } from '../models/credentials.model';

export const credentialsKey = 'credentials';

@Injectable({
  providedIn: 'root',
})
export class CredentialsService {
  private userCredentials: ICredentials | null = null;
  constructor(private router: Router) {
    this.recoverCredentials();
  }

  recoverCredentials(): void {
    const localCredential = localStorage.getItem(credentialsKey);
    if (localCredential) {
      this.userCredentials = JSON.parse(localCredential || '') as ICredentials;
    }
  }

  setCredentials(data: ICredentials | null): void {
    this.userCredentials = data;
    if (data === null) {
      localStorage.removeItem(credentialsKey);
      sessionStorage.removeItem(credentialsKey);
    } else {
      localStorage.setItem(
        credentialsKey,
        JSON.stringify(this.userCredentials)
      );
    }
  }

  get credentials(): ICredentials | null {
    return this.userCredentials;
  }

  static getCredentials(): ICredentials {
    var credentials!: ICredentials;

    const localCredential = localStorage.getItem(credentialsKey);
    const sessionCredential = sessionStorage.getItem(credentialsKey);

    if (localCredential || sessionCredential) {
      credentials = JSON.parse(
        localCredential || sessionCredential || ''
      ) as ICredentials;
    }

    return credentials;
  }

  logout(): void {
    this.setCredentials(null);
    this.router.navigate(['/']).then();
  }

  canActivateLoggedPages(): boolean {
    return !!this.userCredentials?.token;
  }

  isAuthenticated(): boolean {
    this.recoverCredentials();
    return !!this.userCredentials;
  }

  getToken(): string | null {
    return this.credentials?.token ? `Bearer ${this.credentials!.token}` : null;
  }
}
