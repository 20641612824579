<main>
  <section class="login-section">
    <div class="box">
      <div class="title">
        <h2>Recuperar Senha</h2>
        <p>
          {{
            isSend
              ? "Enviamos um link de recuperação para o e-mail informado. Verifique sua caixa de entrada e siga as instruções para cadastrar uma nova senha"
              : "Informe seu e-mail para receber um link e cadastrar uma nova senha"
          }}
        </p>
      </div>
      <div class="form" *ngIf="!isSend">
        <form [formGroup]="form" class="form">
          <div class="item">
            <mat-form-field appearance="outline">
              <mat-label>Usuário</mat-label>
              <input
                matInput
                type="email"
                id="email"
                [formControlName]="'email'"
                placeholder="email@email.com"
                required
              />
            </mat-form-field>
          </div>
          <button
            mat-button
            class="filled"
            (click)="onSend()"
            *ngIf="!isLoading"
          >
            Receber link
          </button>
          <div class="loader" *ngIf="isLoading">
            <mat-spinner
              diameter="24"
              strokeWidth="2"
              class="spinner-position"
            ></mat-spinner>
          </div>
        </form>
      </div>
      <div class="form" *ngIf="isSend">
        <button mat-button class="filled" (click)="resend()" *ngIf="isSend">
          Reenviar link
        </button>
        <div class="loader" *ngIf="isLoading">
          <mat-spinner
            diameter="24"
            strokeWidth="2"
            class="spinner-position"
          ></mat-spinner>
        </div>
      </div>
    </div>
  </section>
</main>
