import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { Router, RouterModule } from '@angular/router';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { FeedbackService } from '../../shared/services/feedback.service';
import { AuthService } from './services/auth.service';
import { LoginBody } from './models/auth.model';
import {
  AngularFireAuth,
  AngularFireAuthModule,
} from '@angular/fire/compat/auth';
@Component({
  selector: 'app-auth',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIcon,
    RouterModule,
    MatProgressSpinner,
  ],
  templateUrl: './auth.component.html',
  styleUrl: './auth.component.scss',
})
export class AuthComponent implements OnInit {
  form!: FormGroup;
  hide = true;
  isLoading: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private feedbackService: FeedbackService,
    private auth: AngularFireAuth,
    private router: Router
  ) {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {}

  async login(): Promise<void> {
    this.form.markAllAsTouched();
    // console.log(this.form.invalid);
    if (this.form.invalid) {
      return;
    }

    this.isLoading = true;
    const body = this.form.getRawValue() as LoginBody;

    this.authService.login(body).subscribe({
      next: (res) => {
        this.isLoading = false;
        const credentials = this.auth
          .signInAnonymously()
          .then((credential) => {
            this.router.navigate(['/dashboard']).then();
          })
          .catch((error) => {
            this.feedbackService.httpError(error);
          });
      },
      error: (err) => {
        this.isLoading = false;
        this.feedbackService.httpError(err);
      },
    });
  }
}
