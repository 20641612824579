import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { LoginBody } from '../models/auth.model';
import { Observable } from 'rxjs';
import { ICredentials } from '../models/credentials.model';
import { Injectable } from '@angular/core';
import { IResponse } from '../../../core/models/response.model';

@Injectable({
  providedIn: 'root',
})
export class AuthRepository {
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  login(body: LoginBody): Observable<IResponse> {
    return this.http.post<IResponse>(`${this.baseUrl}/login`, body);
  }

  logout(): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/logout`, null);
  }
}
