export const environment = {
  production: false,
  apiUrl: 'https://apihomolog.partagemalls.com.br/api/v1/admin',
  firebaseConfig: {
    projectId: 'partage-shopping',
    appId: '1:705711325722:web:f29612c8b818c1bfc547a3',
    storageBucket: 'partage-shopping.appspot.com',
    apiKey: 'AIzaSyD742JQz9dQlPhUBvwKu-DLas8xO6l4LBI',
    authDomain: 'partage-shopping.firebaseapp.com',
    messagingSenderId: '705711325722',
    measurementId: 'G-2RVRYTZXKJ',
  },
  // firebaseConfig: {
  //   projectId: 'partage-b2c',
  //   appId: '1:169562620091:web:b28a942a6f84b9ec00299e',
  //   databaseURL: 'https://partage-b2c.firebaseio.com',
  //   // storageBucket: 'partage-b2c.appspot.com',
  //   storageBucket: 'partage-b2c-staging',
  //   apiKey: 'AIzaSyAOJ31J8Deh3qZC_oCxipjJRRTd4B5v4PA',
  //   authDomain: 'partage-b2c.firebaseapp.com',
  //   messagingSenderId: '169562620091',
  //   measurementId: 'G-YT74E1WCNP',
  // },
};
