<main>
  <section class="login-section">
    <div class="box">
      <img src="/assets/images/logo.png" height="96" />
      <div class="form">
        <form [formGroup]="form" class="form">
          <div class="item">
            <mat-form-field appearance="outline">
              <mat-label>Usuário</mat-label>
              <input
                matInput
                type="email"
                id="email"
                [formControlName]="'email'"
                placeholder="email@email.com"
              />
            </mat-form-field>
          </div>
          <div class="item">
            <mat-form-field appearance="outline">
              <mat-label>Senha</mat-label>
              <input
                matInput
                #passwordInput
                type="password"
                id="password"
                [formControlName]="'password'"
                placeholder="Sua senha pessoal de acesso"
              />
              <mat-icon
                matSuffix
                (click)="
                  hide = !hide;
                  passwordInput.type =
                    passwordInput.type === 'password' ? 'text' : 'password'
                "
                >{{ hide ? "visibility_off" : "visibility" }}</mat-icon
              >
            </mat-form-field>
          </div>
          <a mat-button [routerLink]="['/recuperar-senha']">Esqueceu sua senha ?</a>
          <button mat-button class="filled" (click)="login()" *ngIf="!isLoading">Entrar</button>
          <div class="loader" *ngIf="isLoading">
            <mat-spinner diameter="24" strokeWidth="2" class="spinner-position"></mat-spinner>
          </div>
        </form>
      </div>
    </div>
  </section>
</main>
